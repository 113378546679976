import React, { useRef, useEffect } from "react";

import { Input, Upload, Badge, Alert } from "antd";

import {
  PaperClipOutlined,
  SendOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import styles from "./ChatInputTextArea.module.css";
import { UploadFilePopover } from "../Popeovers";
import { UploadedFilesViewTags } from "../UploadedFilesViewTags";
import { useDrawerContext } from "../../hooks/useDrawerContext";
import { useSpeechHandler } from "../../hooks/useSpeechHandler"; // Import the custom hook
import { VoiceInput } from "../VoiceInput";

const { TextArea } = Input;

export const ChatInputTextArea = ({
  input,
  setInput,
  executeGPTRequest,
  isLoading,
  isSendDisabled,
  callGPTAPI,
  uploadProps,
  uploadProps2,
  showFileUploadButton,
  isFileUploadLoading,
  uploadFileList,
  handleFileRemoveChange,
  errorMessage,
  setErrorMessage,
}) => {
  const { isTypewriting } = useDrawerContext();
  const { listening, onStartListening, onStopListening } = useSpeechHandler(
    input,
    setInput
  );
  const textAreaRef = useRef(null);
  const enableVoiceInput = process.env.REACT_APP_ENABLE_VOICE_INPUT === "true";
  const listeningMessage =
    process.env.REACT_APP_LISTENING_MESSAGE || "Listening...";

  const handleStartListening = () => {
    onStartListening();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleCloseErrorMessage?.();
    }, 5000);
    return () => clearTimeout(timer);
  }, [errorMessage]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []);

  const handleCloseErrorMessage = () => {
    setErrorMessage?.({ isError: false, message: "" });
  };

  const handleSendClick = () => {
    if (!isSendDisabled && !listening) {
      callGPTAPI();
      setInput("");
    }
  };
  return (
    <>
      {errorMessage?.isError ? (
        <Alert
          onClose={handleCloseErrorMessage}
          className={styles.errorAlertStyles}
          message={errorMessage?.message}
          type="error"
          showIcon
          icon={<InfoCircleOutlined className={styles.errorAlertIcon} />}
          closable
        />
      ) : null}
      {listening && (
        <div className={styles.listeningMessageContainer}>
          <div className={styles.listeningMessage}>
            <Badge
              className={styles.badgeDot}
              status="processing"
              text={listeningMessage}
            />
          </div>
        </div>
      )}
      {uploadFileList?.length ? (
        <div className={styles.tagItemsWrapper}>
          <UploadedFilesViewTags
            files={uploadFileList}
            handleFileRemoveChange={handleFileRemoveChange}
          />
        </div>
      ) : null}

      <div className={styles.TextAreaIconsWrapper}>
        <div className={styles.flexWrapperContainer}>
          {isFileUploadLoading ? (
            <div className={styles.iconWrapper}>
              <LoadingOutlined className={styles.uploadLoadingIconStyles} />
            </div>
          ) : (
            <div className={styles.iconWrapper}>
              {!listening && (
                <UploadFilePopover
                  uploadProps={uploadProps}
                  showFileUploadButton={showFileUploadButton || !isTypewriting}
                >
                  <Upload
                    {...uploadProps}
                    disabled={!showFileUploadButton}
                    className={styles.paperClipStyles}
                  >
                    <PaperClipOutlined
                      className={styles.paperClipIcon}
                      style={{
                        cursor:
                          !showFileUploadButton || isTypewriting
                            ? "not-allowed"
                            : "pointer",
                        color:
                          !showFileUploadButton || isTypewriting
                            ? "var(--bdo-charcoal-light)"
                            : "var(--sub-text)",
                      }}
                    />
                  </Upload>
                </UploadFilePopover>
              )}

              {enableVoiceInput && (
                <VoiceInput
                  listening={listening}
                  handleStartListening={handleStartListening}
                  onStopListening={onStopListening}
                  isLoading={isLoading}
                />
              )}
            </div>
          )}
          <TextArea
            ref={textAreaRef}
            value={input}
            readOnly={listening}
            className={styles.textAreaInputStyles}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            id="transcript"
            autoSize={{ minRows: 1, maxRows: 6 }}
            placeholder="Enter your prompt here.."
            onKeyDown={(e) => {
              if (!isSendDisabled && !isLoading && !listening) {
                executeGPTRequest(e);
              }
            }}
          />

          {isLoading ? (
            <div className={styles.loadingIconStyles}>
              <LoadingOutlined />
            </div>
          ) : (
            <SendOutlined
              disabled={isSendDisabled || listening}
              onClick={handleSendClick}
              className={styles.sendIconStyles}
              style={{
                color:
                  isSendDisabled || listening
                    ? "var(--bdo-charcoal-light)"
                    : "var(--bdo-primary)",
                cursor: isSendDisabled || listening ? "not-allowed" : "pointer",
              }}
            />
          )}
        </div>
      </div>
      <p className={styles.infoText}>
        Personas uses AI. You are responsible for validating the output.
      </p>
    </>
  );
};
