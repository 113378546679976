import { useState, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

export const useSpeechHandler = (input, setInput) => {
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const [temp, setTemp] = useState("");

  const [continuous, setContinuous] = useState(
    process.env.REACT_APP_SPEECH_CONTINUOUS === "true" || true
  );
  const [language, setLanguage] = useState(
    process.env.REACT_APP_SPEECH_LANGUAGE || "en-GB"
  );

  useEffect(() => {
    if (listening) {
      setInput(temp + transcript);
    }
  }, [transcript, temp, listening, setInput]);

  const onStartListening = () => {
    setTemp(input);
    resetTranscript();
    SpeechRecognition.startListening({
      continuous,
      language,
    });
  };

  const onStopListening = () => {
    SpeechRecognition.stopListening();
    setTemp("");
    resetTranscript();
  };

  return { listening, onStartListening, onStopListening };
};
