import { useState, useRef, forwardRef, useImperativeHandle } from "react";
import {
  CheckCircleFilled,
  CloseCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Layout, Select, Button, notification, Modal, Upload } from "antd";
import classNames from "classnames";

import { getEncoding } from "js-tiktoken";
import { WrapAroundAgreement } from "../components/popups/wrapAroundAgreement";
import { BDOButton, ButtonColours } from "../components/Button";
import { useGet } from "../hooks/useGet";
import { routes } from "../config/apiRoutes";
import { allowedFileTypes } from "../config/constants";
import { useMutate } from "../hooks/useMutate";
import { useDrawerContext } from "../hooks/useDrawerContext";
import { PersonaSelectionDrawer } from "../modules/PersonaSelectionDrawer";
import { ChatMessage } from "../modules/ChatMessage";
import { ChatInputTextArea } from "../components/ChatInputTextArea";

import styles from "./landPage.module.css";
import { ChatAILoader } from "../components/ChatAILoader";
import { TypingAnimation } from "../components/TypingAnimation/TypingAnimation";
import { EvidenceWindow } from "../modules/EvidenceWindow";
import { checkFileUpload } from "../utils";

const tiktoken = getEncoding("cl100k_base");
// ChatInputTextArea component is imported from src/components/ChatInputTextArea/ChatInputTextArea.jsx
export const LandPage = forwardRef((props, ref) => {
  const { Content } = Layout;
  const [api, contextHolder] = notification.useNotification();

  useImperativeHandle(ref, () => ({
    clearChat,
  }));

  const { Option } = Select;
  const { OptGroup } = Select;
  const [input, setInput] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    isError: false,
    message: "",
  });
  const [chatLog, setChatLog] = useState([]);
  const [chatLogId, setChatLogId] = useState("");
  const [aiCalling, setAiCalling] = useState(false);
  const [requestTokenCount, setRequestTokenCount] = useState(0);
  const [requestTokenRemainCount, setRequestTokenRemainCount] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [personas, setPersonas] = useState([]);
  const [personaText, setPersonaText] = useState("");
  const [maxRequestTokens, setMaxRequestTokens] = useState(
    process.env.REACT_APP_MAX_REQUEST_TOKENS
  );
  const [maxResponseTokens, setMaxResponseTokens] = useState(
    process.env.REACT_APP_MAX_RESPONSE_TOKENS
  );
  const [maxFileUploadLimit, setMaxFileUploadLimit] = useState(
    process.env.REACT_APP_MAX_FILE_UPLOAD_LIMIT
  );
  const [showError, setShowError] = useState(false);
  const [IsFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const [showSavePersonaModal, setShowSavePersonaModal] = useState(false);
  const [showDeletePersonaModal, setShowDeletePersonaModal] = useState(false);
  const [personaName, setPersonaName] = useState("");
  const [customPersonaExist, setCustomPersonaExist] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const uploadFileRef = useRef();
  const [uploadKey, setUploadKey] = useState("");
  const [uploadFileList, setUploadFileList] = useState([]);
  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const defaultGptModel = process.env.REACT_APP_DEFAULT_GPT_MODEL;
  const [showAgreementModal, setAgreementModal] = useState(false);
  const {
    isBDOVoice,
    selectedModel,
    setSelectedModel,
    isMobileView,
    isTabletView,
    setRightsideCollapseStatus,
    isTypewriting,
    setShouldAutoScroll,
    handleSetPdfUrl,
  } = useDrawerContext();
  const [isFileImage, setIsFileImage] = useState(false);
  const selectedPersonaRef = useRef(null);
  const hasUserConsentedRef = useRef(false);

  const { data: userProfileResponse, refetch: refetchUserProfile } = useGet({
    endpoint: routes.getUserProfile,
    onSuccess: (data, _response) => {
      if (!hasUserConsentedRef.current) {
        hasUserConsentedRef.current = !!data?.hasConsented;
        setAgreementModal(!data?.hasConsented);
      }
    },
  });

  const { refetch: refetchPersonas } = useGet({
    endpoint: `${routes.getBDOPersonas}?ProjectName=BDO-Personas`,
    onSuccess: (data, response) => {
      const latestAppVersion = response.headers.get("AppVersion");
      localStorage.setItem("appVersion", latestAppVersion);

      fetchPersonas(data.data.personas);
    },
  });

  const { data: voiceTokenCount } = useGet({
    endpoint: routes.getBDOVoiceTokenCount,
  });

  const { mutate: savePersona } = useMutate({ endpoint: routes.savePersona });
  const { mutate: updatePersona } = useMutate({
    endpoint: routes.updatePersona,
    method: "PATCH",
  });
  const { mutate: deletePersona } = useMutate({
    endpoint: routes.deletePersona,
    method: "PATCH",
  });
  const { mutate: addUserConsent } = useMutate({
    endpoint: routes.addUserConsent,
    method: "PATCH",
  });

  const { mutate: uploadFile, loading: isFileUploading } = useMutate({
    endpoint: routes.uploadFiles,
    onError: (_error) => UploadFileNotification("File upload failed!", false),
  });

  const { mutate: clearFiles, loading: isClearingFiles } = useMutate({
    endpoint: routes.clearUserData,
    onError: (_error) => UploadFileNotification("File removal failed!", false),
  });

  const { mutate: callStreamApi } = useMutate({
    endpoint: routes.callGPTStreamApi,
  });
  const { mutate: callFileStreamApi } = useMutate({
    endpoint: routes.chatWithFileStream,
  });

  const listPersonas = [];
  const systemItems = personas.map((persona) => (
    <Option key={persona.name}>{persona.name}</Option>
  ));
  listPersonas.push(
    <OptGroup key={1} label="BDO Personas">
      {systemItems}
    </OptGroup>
  );

  if (userProfileResponse?.personas) {
    const userItems = userProfileResponse.personas.map((persona) => (
      <Option key={persona.name}>{persona.name}</Option>
    ));
    listPersonas.push(
      <OptGroup key={2} label="Your Personas">
        {userItems}
      </OptGroup>
    );
  }

  function fetchPersonas(personasList) {
    const allPersonas = personasList.map((persona) => ({
      ...persona,
      isCustom: false,
    }));
    setPersonas(allPersonas);

    if (!selectedPersonaRef.current) {
      selectedPersonaRef.current = allPersonas[0];
      setPersonaText(allPersonas[0].description);
    }
  }

  async function callGPTAPI() {
    setShouldAutoScroll(true);
    if (hasUserConsentedRef.current) {
      if (uploadFileList.length > 0) {
        callFileStreamGPTAPI();
      } else {
        callGPTStreamAPI();
      }
    } else {
      hasUserConsentedRef.current = false;
      setAgreementModal(true);
    }
  }

  async function callFileStreamGPTAPI() {
    setShowSpinner(true);
    setIsFeedbackSubmitted(false);
    setInput("");
    let currentChat = [
      ...chatLog,
      { role: "user", message: `${input.trim()}` },
    ];
    setChatLog([...currentChat]);
    let body = JSON.stringify({
      isFileImage: isFileImage,
      history: currentChat,
      isBDOVoice: isBDOVoice,
    });
    setAiCalling(true);
    setTimeout(() => {
      scrollToBottom();
    }, 50);

    const response = await callFileStreamApi({ body });
    const reader = response?.body?.getReader();
    const decoder = new TextDecoder();

    if (response.status === 400) {
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }

        const decodedChunk = decoder.decode(value, { stream: true });

        setChatLog([
          ...currentChat,
          { role: "assistant", message: decodedChunk },
        ]);
        scrollToBottom();
      }
    } else if (response.status === 406) {
      setShowRefreshModal(true);
    } else if (response.status === 200) {
      let resultMessage = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }

        const decodedChunk = decoder.decode(value, { stream: true });
        const splitChunk = decodedChunk.split("~");

        splitChunk.forEach((s) => {
          try {
            if (s.trim() && s.length > 0) {
              var deserializedChunk = JSON.parse(s);
              setChatLogId(deserializedChunk.Id);
              if (deserializedChunk?.Result != null) {
                try {
                  var deserializedSources = JSON.parse(
                    deserializedChunk?.Result
                  );
                  resultMessage += deserializedSources;
                } catch (e) {
                  resultMessage += deserializedChunk?.Result;
                }
              }
            }
          } catch (e) {
            console.log(e);
          }
        });
        setChatLog([
          ...currentChat,
          { role: "assistant", message: resultMessage },
        ]);
        scrollToBottom();
      }
    }

    setAiCalling(false);
    setShowSpinner(false);
    setIsFeedbackSubmitted(true);
    setShowProgress(true);
    setTimeout(() => {
      scrollToBottom();
    }, 50);
  }

  async function callGPTStreamAPI() {
    let historyChat = [];
    let maxHistoryMessages = process.env.REACT_APP_INCLUDE_PREVIOUS_RESULTS;
    if (chatLog.length > maxHistoryMessages) {
      for (
        let i = chatLog.length - maxHistoryMessages;
        i < chatLog.length;
        i++
      ) {
        historyChat.push(chatLog[i]);
      }
    } else {
      for (let i = 0; i < chatLog.length; i++) {
        historyChat.push(chatLog[i]);
      }
    }

    //var hasReachTokenLimit = true;
    var hasReachTokenLimit = await countTokenLimit(historyChat);
    if (!hasReachTokenLimit) {
      setShowSpinner(true);
      setIsFeedbackSubmitted(false);
      setInput("");
      let chatLogNew = [...historyChat, { role: "user", message: `${input}` }];
      let currentChat = [...chatLog, { role: "user", message: `${input}` }];
      setChatLog([...currentChat]);
      // call API method
      let body = JSON.stringify({
        prompt: chatLogNew,
        persona: personaText,
        isBDOVoice: isBDOVoice,
        requestTokenCount: requestTokenCount.toString(),
        responseTokenCount: maxResponseTokens.toString(),
        modelToUse: selectedModel,
      });
      setAiCalling(true);
      setTimeout(() => {
        scrollToBottom();
      }, 50);

      const response = await callStreamApi({ body });
      const reader = response?.body?.getReader();
      const decoder = new TextDecoder();

      if (response.status === 400) {
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            break;
          }

          let resultMessage = "";
          const decodedChunk = decoder.decode(value, { stream: true });
          resultMessage += decodedChunk;

          setChatLog([
            ...currentChat,
            { role: "assistant", message: resultMessage },
          ]);
          scrollToBottom();
        }
      } else if (response.status === 406) {
        setShowRefreshModal(true);
      } else if (response.status === 200) {
        let resultMessage = "";

        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            break;
          }

          const decodedChunk = decoder.decode(value, { stream: true });

          const splitChunk = decodedChunk.split(";");
          const partialMessage = splitChunk.reduce((acc, curr) => {
            try {
              if (curr.trim() && curr.length > 0) {
                const deserializedChunk = JSON.parse(curr);

                if (Object.hasOwn(deserializedChunk, "GptModel")) {
                  setSelectedModel(deserializedChunk.GptModel);
                } else {
                  setChatLogId(deserializedChunk.Id);
                  if (deserializedChunk?.Result !== null) {
                    acc += deserializedChunk?.Result;
                  }
                }
              }

              return acc;
            } catch (e) {
              return acc;
            }
          }, "");

          resultMessage += partialMessage;

          setChatLog([
            ...currentChat,
            { role: "assistant", message: resultMessage },
          ]);
          scrollToBottom();
        }
      }

      setAiCalling(false);
      setShowSpinner(false);
      setIsFeedbackSubmitted(true);
      setShowProgress(true);
      setTimeout(() => {
        scrollToBottom();
      }, 50);
    } else {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  }

  function scrollToBottom() {
    const div = document.getElementById("response-section");
    div.scrollTo({
      top: div.scrollHeight,
      behavior: "smooth",
    });
  }

  function clearChat(deleteFile) {
    handleSetPdfUrl({}, false);
    setIsFeedbackSubmitted(false);
    setChatLog([]);
    setSelectedModel(defaultGptModel);
    setInput("");
    if (deleteFile && uploadFileList.length) {
      deleteAllFiles();
    }
  }

  const onPersonasChange = function (value) {
    const userPersonas =
      userProfileResponse?.personas.map((persona) => ({
        ...persona,
        isCustom: true,
      })) || [];
    const allPersonas = personas.concat(userPersonas);
    selectedPersonaRef.current = allPersonas.filter((f) => f.name === value)[0];
    setPersonaText(allPersonas.filter((f) => f.name === value)[0].description);
  };

  const countTokenLimit = async function (historyChat) {
    var countfield = document.getElementById("transcript");
    const personaTokens = tiktoken.encode(personaText);
    const tokens = tiktoken.encode(countfield.value);
    let history = "";
    historyChat.forEach((item) => {
      history += "\n" + item.message;
    });
    const historyTokens = tiktoken.encode(history);
    var limitCount =
      tokens.length +
      personaTokens.length +
      historyTokens.length +
      (isBDOVoice ? Number(voiceTokenCount?.result) : 0);
    setRequestTokenCount(limitCount);
    setRequestTokenRemainCount(maxRequestTokens - limitCount);

    return limitCount > maxRequestTokens ? true : false;
  };

  //clear localstorage on page close
  window.onbeforeunload = function (e) {
    if (uploadFileList.length > 0) {
      deleteAllFiles();
      localStorage.clear();
      var start = Date.now(),
        now = start;
      var delay = 100; // msec
      while (now - start < delay) {
        now = Date.now();
      }
      // this is needed to avoid to show a confirmation prompt
      delete e["returnValue"];
    }
    localStorage.clear();
  };

  const saveUserPersona = function () {
    setShowSavePersonaModal(true);
  };

  const deleteUserPersona = function () {
    setShowDeletePersonaModal(true);
  };

  function handleCancel() {
    setCustomPersonaExist("");
    setShowSavePersonaModal(false);
    setShowDeletePersonaModal(false);
  }

  function handleOkToRefresh() {
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    localStorage.clear();
    window.location.reload(true);
  }

  const customPersonaNotification = (message, description) => {
    api.open({
      placement: "bottomRight",
      message: message,
      description: description,
      type: "success",
    });
  };

  async function handleSavePersona() {
    const body = JSON.stringify({
      name: personaName,
      description: personaText,
    });

    const res = await savePersona({ body });
    const response = await res.json();

    if (response.isSuccess) {
      await refetchUserProfile();
      await refetchPersonas();
      setShowSavePersonaModal(false);

      selectedPersonaRef.current = {
        name: personaName,
        description: personaText,
        isCustom: true,
      };
      setCustomPersonaExist("");
      customPersonaNotification("Persona Added", response.resultMessage);
    } else {
      setCustomPersonaExist(response.resultMessage);
    }
  }

  async function handleUpdatePersona() {
    const body = JSON.stringify({
      name: selectedPersonaRef.current.name,
      description: personaText,
    });

    const res = await updatePersona({ body });
    const response = await res.json();

    if (response.isSuccess) {
      await refetchUserProfile();
      customPersonaNotification("Persona Updated", response.resultMessage);
    } else {
      setCustomPersonaExist(response.resultMessage);
    }
  }

  async function handleDeletePersona() {
    const body = JSON.stringify({ name: selectedPersonaRef.current.name });

    const res = await deletePersona({ body });
    const response = await res.json();

    if (response.isSuccess) {
      await refetchUserProfile();
      setShowDeletePersonaModal(false);
      setCustomPersonaExist("");
      selectedPersonaRef.current = personas[0];
      setPersonaText(personas[0].description);
      customPersonaNotification("Persona Deleted", response.resultMessage);
    } else {
      setCustomPersonaExist(response.resultMessage);
    }
  }

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("done");
    }, 0);
  };

  async function beforeUpload(file) {
    if (file.type.startsWith("image")) {
      setIsFileImage(true);
    } else {
      setIsFileImage(false);
    }
    const isValid = await checkFileUpload(
      file,
      setErrorMessage,
      uploadFileList
    );
    if (isValid) {
      setUploadedFile(file);
      return true;
    }
    return Upload.LIST_IGNORE;
  }

  function handleFileUploadChange(info) {
    if (info.file.status === "done") {
      UploadFileSubmit(info.file.originFileObj, true);
      clearChat(false);
    } else if (info.file.status === "error") {
      UploadFileNotification("File upload failed!", false);
    } else if (info.file.status === "removed") {
      clearChat(false);
      UploadFileSubmit(info.file.originFileObj, false);
    }
  }

  function handleFileRemoveChange(file, index) {
    clearChat(false);
    UploadFileSubmit(file, false, index);
  }

  const uploadProps = {
    name: "file",
    ref: uploadFileRef,
    key: uploadKey,
    showUploadList: false,
    customRequest: dummyRequest,
    accept: allowedFileTypes.toString(),
    beforeUpload: beforeUpload,
    onChange: handleFileUploadChange,
    onRemove: handleFileRemoveChange,
  };

  const UploadFileNotification = (message, isSuccess, description) => {
    api.info({
      placement: "bottomRight",
      message,
      icon: isSuccess ? (
        <CheckCircleFilled className={styles.iconCheck} />
      ) : (
        <CloseCircleOutlined className={styles.iconClose} />
      ),
      duration: 3,
      description,
    });
  };

  const UploadFileSubmit = async (
    file,
    isUploadFile,
    index,
    isMultiple = false
  ) => {
    const formData = new FormData();
    formData.append("isUploadFile", isUploadFile);

    if (isMultiple) {
      file.forEach((f) => {
        formData.append(`files`, f);
      });
    } else {
      formData.append("files", file);
    }

    const response = await uploadFile({ body: formData });

    if (response?.ok) {
      if (isUploadFile) {
        UploadFileNotification("File uploaded successfully!", true);
        setUploadFileList((prevState) => [...prevState, uploadedFile]);
      } else {
        UploadFileNotification("File removed successfully!", true);
        setUploadedFile(null);
        if (index == null) {
          setUploadFileList([]);
        } else {
          const newArray = [...uploadFileList];
          newArray.splice(index, 1);
          setUploadFileList(newArray);
        }
      }
    }
  };

  const resetFileInput = () => {
    setUploadKey(Math.random().toString(36));
  };

  const executeGPTRequest = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      callGPTAPI();
    }
  };

  async function deleteAllFiles() {
    resetFileInput();

    await clearFiles({ body: JSON.stringify({}) });

    UploadFileNotification("File removed successfully!", true);
    setUploadFileList([]);
  }

  async function saveUserAgreement() {
    hasUserConsentedRef.current = true;
    setAgreementModal(false);
    await addUserConsent({ body: JSON.stringify({}) });
  }

  const divRef = useRef();
  const handleUserScroll = () => {
    if (divRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = divRef.current;
      // If user scrolls up, stop auto-scrolling
      if (scrollHeight - scrollTop > clientHeight + 10) {
        setShouldAutoScroll(false);
      } else {
        setShouldAutoScroll(true);
      }
    }
  };
  return (
    <Layout className="" style={{ margintop: "53px", flex: 1 }}>
      {contextHolder}
      <EvidenceWindow
        title="Resizable Drawer"
        placement="left"
        closable={false}
        destroyOnClose
        visible={true}
      />
      <>
        <Content className="content-area">
          <section
            className="justify-spaced flex-col"
            style={{
              height: "100%",
              minHeight: "-webkit-fill-available",
              minWidth: "340px",
            }}
          >
            <section
              ref={divRef}
              onScroll={handleUserScroll}
              className={classNames("bdo-pane-top", {
                [styles.chatWrapper]: !chatLog.length,
              })}
              id="response-section"
            >
              <div className="chat-log ai-background" id="chat-logs">
                {!chatLog.length ? (
                  <div className={styles.chatBgImageWrapper}>
                    <img
                      src="chat_bg.svg"
                      alt="Logo"
                      style={{
                        height:
                          !isMobileView && !isTabletView ? "122.5px" : "84px",
                      }}
                    />
                    <h3 className={styles.imageWelcometext}>
                      <TypingAnimation message="Hello, how can I help you today?"></TypingAnimation>
                    </h3>
                    {isMobileView || isTabletView ? (
                      <div className={styles.imageLableContainer}>
                        <BDOButton
                          onClick={() => setRightsideCollapseStatus(false)}
                          variant={ButtonColours.TEXT_ICON}
                        >
                          <PlusOutlined /> Add Persona
                        </BDOButton>
                      </div>
                    ) : null}
                  </div>
                ) : null}

                {chatLog.map((message, index) => (
                  <ChatMessage
                    key={index}
                    message={message}
                    isLastMessage={index === chatLog?.length - 1}
                    scrollToBottom={scrollToBottom}
                  />
                ))}
                <ChatAILoader isLoading={aiCalling} />
              </div>
            </section>
            <div className={styles.sectionWrapper}>
              <section className={classNames(styles.enterTextSectioWrapper)}>
                <ChatInputTextArea
                  setInput={setInput}
                  input={input}
                  setErrorMessage={setErrorMessage}
                  errorMessage={errorMessage}
                  executeGPTRequest={executeGPTRequest}
                  isSendDisabled={!input.length || isFileUploading}
                  isLoading={showSpinner || isTypewriting}
                  callGPTAPI={callGPTAPI}
                  uploadProps={uploadProps}
                  showFileUploadButton={
                    !isFileUploading &&
                    !isClearingFiles &&
                    !showSpinner &&
                    uploadFileList.length < maxFileUploadLimit
                  }
                  isFileUploadLoading={isFileUploading || isClearingFiles}
                  uploadFileList={uploadFileList}
                  handleFileRemoveChange={handleFileRemoveChange}
                />
              </section>
            </div>
          </section>
        </Content>
      </>

      <PersonaSelectionDrawer
        {...{
          onPersonasChange,
          listPersonas,
          selectedPersonaRef,
          personaText,
          saveUserPersona,
          setPersonaText,
          handleUpdatePersona,
          deleteUserPersona,
          customPersonaExist,
          handleSavePersona,
          showSavePersonaModal,
          handleCancel,
          personaName,
          setPersonaName,
          showDeletePersonaModal,
          handleDeletePersona,
        }}
      />
      <Modal
        title="New Updates"
        open={showRefreshModal}
        destroyOnClose={true}
        maskClosable={false}
        closable={true}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              handleOkToRefresh();
            }}
          >
            Refresh
          </Button>,
        ]}
      >
        <p>
          A new version of Personas is available! To learn more about the new
          changes, please select the new updates button at the top of the
          screen.
        </p>
      </Modal>
      <Modal
        width={880}
        open={showAgreementModal}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              saveUserAgreement();
            }}
          >
            I agree
          </Button>,
        ]}
      >
        <WrapAroundAgreement />
      </Modal>
    </Layout>
  );
});

export default LandPage;
