import { notification, Tooltip } from "antd";
import classNames from "classnames";
import styles from "./ChatMessage.module.css";
import { useDrawerContext } from "../../hooks/useDrawerContext";
import { CopytOutlinedIcon } from "../../Icons/CopyIcon";
import Markdown from "markdown-to-jsx";
import { ButtonColours } from "../../components/Button";
import { routes } from "../../config/apiRoutes";
import { useGetPDF } from "../../hooks/useGetPDF";
import { useRef, useState } from "react";

export const ChatMessage = ({ message, isLastMessage }) => {
  const [api, contextHolder] = notification.useNotification();
  const { isTypewriting, handleSetPdfUrl, showEvidencesDrawer } =
    useDrawerContext();

  const evidenceName = useRef("");

  const { getData: getEvidenceFile } = useGetPDF({
    endpoint: routes.getEvidence,
    onSuccess: (response) => {
      displayPDF(response);
    },
    method: "POST",
  });
  const displayPDF = async (blobContent) => {
    const blob = await blobContent.blob();
    const url = URL.createObjectURL(blob);
    handleSetPdfUrl({ url, name: evidenceName.current }, true);
  };
  const copyText = () => {
    openNotification();
    navigator.clipboard.writeText(message.message);
  };

  const openNotification = () => {
    api.open({
      placement: "bottomRight",
      message: "Copied to clipboard",
      description: "Text has been copied to clipboard.",
      type: "success",
    });
  };

  const splitBySources =
    message.message != null &&
    message.message.split("SOURCES:").map((item) => item.trim());
  const splitBySquareBrackets =
    splitBySources.length > 1 && splitBySources[1].split(",");
  const noAnswerFound = splitBySources[0].search("I'm sorry");

  const getSourceElement = (source) => {
    if (source?.includes(".pdf")) {
      return (
        <a
          role="button"
          className={styles.linkButton}
          variant={ButtonColours.LINK}
          onClick={() => {
            getEvidenceFile({ Name: source });
            evidenceName.current = source;
          }}
        >
          {source}
        </a>
      );
    } else {
      return <p>{source}</p>;
    }
  };
  const formatMDX = (input) => {
    return input
      .split(" ")
      .map((word, index) => {
        if (word.startsWith("#") && index !== 0) {
          return "\n" + word;
        }
        return word;
      })
      .join(" ");
  };
  return (
    <div
      className={`chat-message ${message.role === "assistant" && "chatgpt"}`}
    >
      {contextHolder}
      <div
        style={{ padding: showEvidencesDrawer ? "2rem 1rem" : "2rem 5rem" }}
        className={classNames(
          "chat-message-center flex-row",
          styles.chatWrapper
        )}
      >
        <div className={styles.chatItem}>
          <div
            className="flex-row"
            style={{
              width: "100%",
            }}
          >
            <div className={styles.avatarWrapper}>
              {message.role === "assistant" ? (
                <img
                  src="ai_stars.svg"
                  alt="Logo"
                  style={{
                    height: "21px",
                  }}
                />
              ) : (
                <img
                  src="user_persona.svg"
                  alt="Logo"
                  style={{
                    height: "21px",
                  }}
                />
              )}
            </div>
            <div className="message">
              {message.role !== "assistant" && message.message}
              {message.role === "assistant" && (
                <span>
                  <Markdown>{formatMDX(splitBySources?.[0] ?? "")}</Markdown>
                  {splitBySquareBrackets.length > 1 &&
                    noAnswerFound !== 0 &&
                    (!isTypewriting || !isLastMessage) && (
                      <>
                        <br />
                        <br />
                        <div>
                          <span>
                            <b>Sources:</b>
                          </span>
                          <ul style={{ listStylePosition: "inherit" }}>
                            {splitBySquareBrackets.map((source, index) => {
                              if (index > 0) {
                                return (
                                  <li key={index}>
                                    {getSourceElement(source)}
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        </div>
                      </>
                    )}
                </span>
              )}
            </div>
            <div
              className="flex-row"
              style={{ alignItems: "flex-start", paddingTop: "6px" }}
            >
              <div className="flex-row actionNotifications" onClick={copyText}>
                <Tooltip title="Copy to clipboard">
                  <CopytOutlinedIcon className={styles.copyButtonStyles} />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
