import { Popover } from "antd";
import styles from "./popeover.module.css";

const Content = () => {
  return (
    <div className={styles.contentWrapper}>
      <h1 className={styles.mainHeading}>Start Voice Input</h1>
      <p className={styles.textStyles}>
        Talk to Personas: convert speech to text.
      </p>
    </div>
  );
};

export const VoicePopover = ({ children, title }) => {
  return (
    <Popover placement="topLeft" title={title} content={<Content />}>
      {children}
    </Popover>
  );
};
