import { Popover } from "antd";
import styles from "./popeover.module.css";

const Content = () => {
  const maxFileUploadLimit = process.env.REACT_APP_MAX_FILE_UPLOAD_LIMIT;
  return (
    <div className={styles.contentWrapper}>
      <h1 className={styles.mainHeading}>Upload a File or Image</h1>
      <p className={styles.textStyles}>
        {maxFileUploadLimit > 1 && (
          <>
            Upload file(s) (<strong>Max: {maxFileUploadLimit}</strong>) and chat
            with them.
            <br />
          </>
        )}
        This includes .pdf, .docx, .xlsx, .txt up to the size of 20MB and images
        up to 10MB.
      </p>
    </div>
  );
};

export const UploadFilePopover = ({
  children,
  title,
  uploadProps,
  showFileUploadButton,
}) => {
  return (
    <Popover
      placement="topLeft"
      title={title}
      content={
        showFileUploadButton ? <Content uploadProps={uploadProps} /> : null
      }
    >
      {children}
    </Popover>
  );
};
