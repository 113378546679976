import { Image, Tooltip } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";

import styles from "./UploadedFilesViewTags.module.css";
import { imageFileRegex } from "../../config/constants";

export const UploadedFilesViewTags = ({ files, handleFileRemoveChange }) => {
  const truncateFileName = (fileName, maxLength) => {
    if (fileName.length <= maxLength) return fileName;

    const extension = fileName.substring(fileName.lastIndexOf("."));
    const nameWithoutExtension = fileName.substring(
      0,
      fileName.lastIndexOf(".")
    );
    const firstPart = nameWithoutExtension.substring(0, 4);
    const lastPart = nameWithoutExtension.substring(
      nameWithoutExtension.length - 2
    );

    return `${firstPart}...${lastPart}${extension}`;
  };

  const getImageByFileType = (file) => {
    let src;
    switch (true) {
      case file.name.endsWith(".pdf"):
        src = "/pdf.jpg";
        break;
      case file.name.endsWith(".txt"):
        src = "/txt.jpg";
        break;
      case file.name.endsWith(".docx"):
        src = "/docx.jpg";
        break;
      case file.name.endsWith(".xlsx"):
        src = "/xlsx.jpg";
        break;
      default:
        src = "";
        break;
    }
    return src;
  };
  return (
    <div className={styles.tagWrapper}>
      {files?.map((file, index) => (
        <span
          key={file}
          style={{
            display: "inline-block",
            position: "relative",
          }}
        >
          <div className={styles.imageFileTagStyles}>
            <div
              className={
                imageFileRegex.test(file.name)
                  ? styles.imageStyles
                  : styles.imageFileStyles
              }
            >
              <div className={styles.fileImageStyles}>
                {imageFileRegex.test(file.name) ? (
                  <Image
                    style={{ maxHeight: "53px" }}
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    preview={false}
                  />
                ) : (
                  <Image
                    src={getImageByFileType(file)}
                    alt={getImageByFileType(file)
                      ?.replaceAll(".jpg", "")
                      ?.replaceAll("/", "")}
                    preview={false}
                  />
                )}
              </div>
              <span
                role="button"
                className={styles.imageCloseStyles}
                onClick={(e) => {
                  e.preventDefault();
                  handleFileRemoveChange(file, index);
                }}
              >
                <CloseCircleFilled />
              </span>
            </div>
            <Tooltip title={file.name}>
              <span className={styles.ellipsisFileName}>
                {truncateFileName(file.name, 10)}
              </span>
            </Tooltip>
          </div>
        </span>
      ))}
    </div>
  );
};
